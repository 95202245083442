<template>
  <cs-page class="views-product-success text-center bg-white">
    <ProcessStep :step="3" />
    <img
      src="../../../public/img/bg-order-success.png"
      style="height: 157px; margin-left: -50px; margin-top: 20px"
    />
    <p class="text-1 fw-bold">恭喜您，下单成功！</p>
    <p class="text-2">请前往工作台开启智能财务之旅</p>
    <p>
      <a-button
        type="primary"
        href="/ucs/home"
        target="_blank"
        class="btn-center"
        style="font-size: 18px"
        >工作台</a-button
      >
    </p>
    <a-button type="link" @click="orderDetialsClick">查看订单</a-button>
  </cs-page>
</template>
<script>
export default {
  components: {
    ProcessStep: () => import("@/components/product/ProcessStep"),
  },
  inject: ["loadUserInfo"],
  data() {
    return {};
  },
  created() {
    // 更新当前用户信息
    this.loadUserInfo();
  },
  methods: {
    orderDetialsClick() {
      const { id } = this.$route.query;
      this.$router.replace({
        path: "/user/orderDetails",
        query: {
          id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.views-product-success {
  .text-1 {
    font-size: 20px;
    color: #000000;
    margin-top: 30px;
  }
  .text-2 {
    font-size: 18px;
    color: #000000;
  }
  .btn-center {
    width: 239px;
    height: 46px;
    line-height: 42px;
    border-radius: 0;
    margin-top: 20px;
  }
}
</style>